import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForVijTaxiService from './FAQsComponentForVijTaxiService';
import SRTravelOptionsForVTS from './SRTravelOptionsForVTS';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/vts-1.png"),
    require("../assets/img/vts-8.png"),
    require("../assets/img/vts-9.png"),
];

const VijayawadaTaxiServices = () => {
    return (
        <>
            <Helmet>
                <title>Vijayawada Taxi Services | SR Car Travels</title>
                <meta name="description" content="SR Car Travels aims to redefine your travel experience by providing stress-free and enjoyable Vijayawada taxi services. Send your inquiries to contact@srcartravels.in" />
                <link rel="canonical" href="https://srcartravels.in/vijayawada-taxi-services" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <SRCustomForm />
            <br/><br/><br/><br/>
            <div className="stress-free-travel-banner py-md-5 mt-md-5" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',  maxWidth: '1119px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[0]}
                        alt="Businessman in Car"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h1 style={{ fontSize: '2rem' }}>Forget Travel Stress, Embrace Smooth Journeys with Our Vijayawada Taxi Services</h1>
                    <p>Vijayawada is the city where history and innovation intertwine. Planning your travel arrangements can often resemble navigating through a vibrant marketplace. Whether you're a seasoned explorer or a first-time visitor, the prospect of arranging transportation can add unnecessary stress to your itinerary. Say goodbye to the hassle and hello to smooth sailing with <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>SR Car Travels</a>’ Vijayawada taxi services. We believe every journey should be an enjoyable experience, free from the worries of car rentals, unfamiliar routes, and hidden fees.</p>
                </div>
            </div>
            <br></br>

            <SRTravelOptionsForVTS />
            <br></br>
            <div className="gateway-to-vijayawada-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1144px', margin: 'auto' }}>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h2>Beyond the Ride: Your Stress-Free Gateway to Exploring Vijayawada</h2>
                    <p>At SR Car Travels, our commitment goes beyond simply getting you from point A to point B. We offer a diverse fleet of well-maintained vehicles to accommodate your travel requirements, from compact hatchbacks for solo adventures to spacious SUVs for family outings. Experienced and courteous drivers ensure a safe and comfortable ride, while our transparent pricing structure eliminates any unpleasant surprises.</p>
                    <p>So, leave the stress to us and <a href="https://srcartravels.in/best-car-travels-in-vijayawada" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>embrace the excitement of exploring Vijayawada</a>. Let our local expertise and dedication to seamless travel transform your experience into one filled with lasting memories.</p>
                    <a href="https://srcartravels.in/sp-offers" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                        Know More
                    </a>
                </div>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[1]}
                        alt="Joyride"
                        effect="blur"
                        className="img-fluid rounded-end"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '0 10px 10px 0' }}
                    />
                </div>
            </div>
            <br></br>
            <div className="summary-component" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '1152px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[2]}
                        alt="Summing It Up"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h2>Summing It Up</h2>
                    <p>SR Car Travels aims to redefine your travel experience by providing stress-free and enjoyable Vijayawada taxi services. Our commitment extends beyond mere transportation as we offer a diverse fleet of well-maintained vehicles, experienced and courteous drivers, and transparent pricing to ensure a seamless journey for our customers. With us, you can leave behind the worries of car rentals, unfamiliar routes, and hidden fees. Embrace the excitement of exploring Vijayawada while we handle all the stress associated with travel.</p>
                    <p>So if you're a traveler looking for an effortless <a href="https://srcartravels.in/car-travelers-near-me" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>navigate through Vijayawada</a> without any car ownership hassle or financial burdens, SR Car Travels is here to make your exploration truly memorable.</p>
                    <div className="contact-info">
                        <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a></p>
                        <p><a href="mailto:contact@srcartravels.in" className="btn btn-primary">📧 contact@srcartravels.in</a></p>
                    </div>

                    <a href="https://srcartravels.in/" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                        Know More
                    </a>
                </div>
            </div>
            <br></br>
            <FAQsComponentForVijTaxiService />
        </>
    );
};

export default VijayawadaTaxiServices;
