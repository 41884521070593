import React, { useState, useEffect, lazy, Suspense } from 'react';
import '../../features/features.css';
import '../../form/price/BookingForm.css';

// Lazy load SRSubmissionResults component
const SRSubmissionResults = lazy(() => import('./SRSubmissionResults'));

const imagePaths = {  
    bannerImage: require("../../assets/img/home_banner_img.png"),
    whatsAppImage: require("../../assets/img/whatsapp.png"),
    callMobileImage: require("../../assets/img/callMobile.png")
};


const SRCustomForm = () => {
    const [tripType, setTripType] = useState('oneWay');
    const [cities, setCities] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState({ fromCity: false, toCity: false });
    const [submissionResponse, setSubmissionResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // State for loading indication
    const [journeyDetails, setJourneyDetails] = useState(null); // State to hold journey details for the user

    const [formData, setFormData] = useState({
        fromCity: '',
        toCity: '',
        pickUpDate: '',
        returnDate: '',
        mobNumber: '',
        tripType: 'oneWay',
    });

    useEffect(() => {
        // Prefetch the background image
        const link = document.createElement('link');
        link.rel = 'prefetch';
        link.href = '/src/assets/img/home_banner_img.webp';
        document.head.appendChild(link);

        // Cleanup function to remove the prefetch link if the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    useEffect(() => {
        if (formData.fromCity && showSuggestions.fromCity) {
            fetchCities(formData.fromCity, 'fromCity');
        }
        if (formData.toCity && showSuggestions.toCity) {
            fetchCities(formData.toCity, 'toCity');
        }
    }, [formData.fromCity, formData.toCity, showSuggestions]);

    const fetchCities = async (query, field) => {
        try {
            const response = await fetch(`https://srcartravels.in/cities?search=${query}`);
            const data = await response.json();
            setCities(data.cities);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'fromCity' || name === 'toCity') {
            setShowSuggestions({ ...showSuggestions, [name]: true });
        }
    };

    const handleCitySelect = (cityName, field) => {
        setFormData({ ...formData, [field]: cityName });
        setShowSuggestions({ fromCity: false, toCity: false });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch('https://srcartravels.in/submittedData', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            console.log('Form submission response:', data);
            setSubmissionResponse(data);
            setJourneyDetails({
                fromCity: formData.fromCity,
                toCity: formData.toCity,
                pickUpDate: formData.pickUpDate,
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderCitySuggestions = (fieldName) => {
        return showSuggestions[fieldName] && cities.length > 0 && (
            <div className="list-group position-absolute w-100" style={{ zIndex: 1 }}>
                {cities.map((city, index) => (
                    <button type="button" key={index} className="list-group-item list-group-item-action"
                        onClick={() => handleCitySelect(city.cityName, fieldName)}>
                        {city.cityName}
                    </button>
                ))}
            </div>
        );
    };
    return (
        <>
            <div>  
                <div class="callus-btn">
                    <a href="tel:8886556632" target="_blank">
                    <img src={imagePaths.callMobileImage} className="banner-backdrop d-block w-100"  loading="lazy" /> Call Us
                    </a>
                </div>

                <div class="whatsapp-btn">
                    <a href="https://wa.me/9346397115" target="_blank">
                    <i class="bi bi-whatsapp"></i> WhatsApp
                    </a>
                </div>


                <img src={imagePaths.bannerImage} className="banner-backdrop d-block w-100"  loading="lazy" />
                <h2 className="mb-3 text-center banner-title-h1">
                 Car Travels in Vijayawada
                </h2>
                <div className=" py-3">
                    <form id="myForm" onSubmit={handleSubmit} > {/* Center the form */}
                     
                        <div className="booking-form">
                            <div className="radio-buttons">
                                <label className="">
                                    <input
                                        type="radio"
                                        name="tripType"
                                        value="oneWay"
                                        id="oneWay"
                                        checked={formData.tripType === 'oneWay'} onChange={handleInputChange}
                                    />
                                    <span className='px-2'><b>One way</b></span>
                                </label>
                                <label className="">
                                    <input
                                        type="radio"
                                        name="tripType"
                                        value="roundTrip"
                                        id="roundTrip"
                                        checked={formData.tripType === 'roundTrip'} onChange={handleInputChange}
                                    />
                                      <span className='px-2'><b>Round Trip</b></span>
                                </label>
                            </div>

                            <div className="">
                                <label class="px-1 pb-3">
                                    <b>From</b>
                                    <div>
                                        <input type="text" class="form-control" id="fromCity" name="fromCity" placeholder="Enter Pickup City" required value={formData.fromCity} onChange={handleInputChange} autoComplete="off" />
                                        <div>{formData.fromCity && renderCitySuggestions('fromCity')}</div>
                                    </div>


                                </label>
                                <label class="px-1 pb-3">
                                    <b>To</b>
                                    <div>
                                        <div class="input-group position-relative">
                                            <input type="text" class="form-control" id="toCity" name="toCity" placeholder="Enter Destination City" required value={formData.toCity} onChange={handleInputChange} autoComplete="off" />
                                        </div>
                                        <div>{formData.toCity && renderCitySuggestions('toCity')}</div>
                                    </div>
                                </label>
                                <label class="px-1 pb-3">
                                    <b>PickUp Date</b>
                                    <div>
                                        <input type="datetime-local" id="pickUpDate" name="pickUpDate" required value={formData.pickUpDate} onChange={handleInputChange} />
                                    </div>
                                </label>
                                <label className={formData.tripType === 'oneWay' ? 'disabled pb-3' : 'pb-3'}>
                                    <b>Return Date</b>
                                    <div>
                                        <input type="datetime-local" id="returnDate" name="returnDate" required value={formData.returnDate} onChange={handleInputChange} disabled={formData.tripType === 'oneWay'} />
                                    </div>
                                </label>
                                <label class="px-1 pb-3">
                                    <b>Mobile Number</b>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="mobNumber" name="mobNumber" placeholder="Mobile Number" pattern="[6789][0-9]{9}" autoComplete="off" required value={formData.mobNumber} onChange={handleInputChange} />
                                    </div>
                                </label>
                            </div>

                            <div id='getPriceID' className="d-flex justify-content-center py-4">
                                <button className="btn btn-info text-white" type="submit">GET PRICE</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {isLoading && (
                <div className="text-center text-danger" style={{ fontWeight: 'bold', fontSize: '24px', marginTop: '20px' }}>
                    Please wait...
                </div>
            )}
            {journeyDetails && (
                <div className="text-center my-4 search-result-text">
                    <p>Your planned journey from <strong>{journeyDetails.fromCity}</strong> to <strong>{journeyDetails.toCity}</strong> on <strong>{journeyDetails.pickUpDate}</strong>.</p>
                    <p>Call us directly for the <strong style={{ color: '#007BFF' }}>best rates</strong>. Prices may vary. See the results below.</p>
                </div>
            )}

            {(formData.fromCity.includes('Hyderabad') || formData.toCity.includes('Hyderabad')) && (
                <div className="blinkDescr text-center" style={{ color: '#007BFF', fontWeight: 'bold', fontSize: '20px', marginBottom: '20px' }}>
                    Vijayawada ↔ Hyderabad special packages available at Rs. 5250. Check below.
                </div>
            )}
            {submissionResponse && <SRSubmissionResults data={submissionResponse} />}
        </>

    );
};

export default SRCustomForm;
