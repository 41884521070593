import React from 'react';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForTaxiServicesInVijayawada from './FAQsComponentForTaxiServicesInVijayawada';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SRTravelOptionsForTSIV from './SRTravelOptionsForTSIV';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/TSIV-1.png"),
    require("../assets/img/TSIV-8.png"),
    require("../assets/img/TSIV-9.png"),
    require("../assets/img/TSIV-10.png"),
];

const TaxiServicesInVijayawada = () => {
    return (
        <>
            <Helmet>
                <title>Taxi Services in Vijayawada - SR Car Travels</title>
                <meta name="description" content="SR Car Travels is the premier choice for accessing outstanding taxi services in Vijayawada. We are dedicated to addressing concerns about comfort, safety, and convenience." />
                <link rel="canonical" href="https://srcartravels.in/taxi-services-in-vijayawada" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <SRCustomForm />
            <br/><br/>
            <div className="stress-free-travel-banner py-md-5 my-md-5" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',   maxWidth: '1119px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[0]}
                        alt="Taxi Service in vijayawada"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h1 style={{ fontSize: '2rem' }}>Choose SR Car Travels Taxi Services in Vijayawada For Stress-free Travel</h1>
                    <p>Introducing SR Car Travels, your premier choice for accessing top-notch taxi services in Vijayawada. Navigating the busy city streets or organizing outstation trips can often be overwhelming due to worries about reliability, safety, and convenience. At <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>SR Car Travels</a>, our mission is to address these concerns and ensure your travel experience is smooth and hassle-free. We prioritize transparency, comfort, and customer satisfaction to ensure that every journey with us meets and exceeds your expectations. </p>

                </div>
            </div>
            <br></br>
            <SRTravelOptionsForTSIV />
            <br></br>
            <div className="rental-service-section" style={{ backgroundColor: '#eef4f7', padding: '40px', borderRadius: '15px', maxWidth: '1171px', margin: 'auto' }}>
                <div className="row">
                    <div className="col-md-8">
                        <h3>Your Ride, Your Way: Explore Affordable & Luxury Cabs with SR Car Travels</h3>
                        <p>Begin a journey tailored to your preferences with SR Car/ taxi services in Vijayawada. Our diverse range of affordable and luxury cab options is designed to suit every traveler's needs. Whether you require budget-friendly transportation for your daily commute or seek a luxurious ride for a special occasion, we have the ideal solution for you. </p>
                        <p>Our fleet comprises a wide array of vehicles, ranging from cost-effective choices to <a href="https://srcartravels.in/best-car-travels-in-vijayawada" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>premium rides</a>. We offer you the opportunity to travel in elegance without exceeding your budget. At SR Car Travels, indulge in a smooth and indulgent travel journey that upholds both excellence and affordability.</p>
                        <p>Discover the convenience, flexibility, and affordability of exploring Vijayawada on your own terms with SR Car Travels – where every journey reflects professionalism at its best.
                            Click <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>here</a> for more details. </p>
                        <a
                            className="btn btn-primary"
                            style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                            href="https://srcartravels.in/sp-offers"
                        >
                            Know More
                        </a>
                    </div>
                    <div className="col-md-4">
                        <LazyLoadImage
                            src={imagePaths[1]}
                            alt="SR Car Travels"
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>
                </div>
            </div>
            <br></br>

            <div class="container">
            <h2 className="text-center mb-4 fw-bold fs-4">Local Places to Sightseeing in Vijayawada With SR Car Travels</h2>
            <div>
                <p className='stress-free-travel-banner text-center'>Discover the vibrant city of Vijayawada effortlessly and conveniently through our professional taxi services. Uncover local attractions that guarantee unforgettable sightseeing experiences for travelers with diverse interests. </p>
            </div>

            <div className="stress-free-travel-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',  maxWidth: '1119px', margin: 'auto' }}>

                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[2]}
                        alt="Taxi Service in vijayawada"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>

                <div style={{ flex: '2', padding: '20px' }}>
                    <div style={{ flex: '2', padding: '20px' }}>
                        <ul>
                            <li>Start our journey by visiting the iconic Kanaka Durga Temple, magnificently perched atop Indrakeeladri Hill, providing mesmerizing city skyline views.</li>
                            <li>Delve into history at the Undavalli Caves, admiring ancient rock-cut architecture and elaborate sculptures adorning its interiors.</li>
                            <li>Enjoy a leisurely drive along the picturesque banks of the Krishna River, pausing to admire Prakasam Barrage's architectural marvel and savoring Bhavani Island’s serene ambiance.</li>
                        </ul>
                    </div>
                    <p>With our dependable taxi services in Vijayawada, embark on an exceptional sightseeing adventure as you explore Vijayawada’s local treasures at your own pace and convenience.</p>

                </div>
            </div>

            </div >

            <br></br>

            <div className="container summary-component" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',   margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[3]}
                        alt="Taxi Services in Vijayawada"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h2 style={{ fontSize: '2rem' }}>SR Car Travels: Affordability & Luxury. Book Now!</h2>
                    <p>SR Car Travels is the premier choice for accessing outstanding taxi services in Vijayawada. We are dedicated to addressing concerns about comfort, safety, and convenience. With a focus on transparency, reliability, and customer satisfaction, our mission is to provide a smooth and hassle-free travel experience that exceeds expectations. Whether you seek affordable or luxury cab options, our diverse range of vehicles is tailored to suit every traveler's needs. Trust SR Car Travels to deliver your ride, your way, ensuring a comfortable and personalized journey for every occasion.</p>
                    <p>To book a reliable taxi service in Vijayawada, visit our <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>website</a>.</p>
                    <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a>&nbsp;&nbsp;&nbsp;<a href="mailto:contact@srcartravels.in" className="btn btn-primary"> 📧 contact@srcartravels.in</a></p>
                    <p></p>
                    <a href="https://srcartravels.in/" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                        Know More
                    </a>
                </div>
            </div>

            <br></br>
            <FAQsComponentForTaxiServicesInVijayawada />
        </>
    );
};

export default TaxiServicesInVijayawada;
