import React from 'react';
import SRHeader from '../components/common/SRHeader';
import SRCallNowButton from '../components/common/SRCallNowButton';
import SrCarTravelsMap from '../components/common/SrCarTravelsMap';
import SRFooter from '../components/common/SRFooter';
import SRCustomForm from '../components/common/SRCustomForm';

const LayoutKeyWordPages = ({ children }) => {
    return (
        <>
            <SRHeader /> 
            <SRCustomForm />
            {children}
            <SrCarTravelsMap />
            <SRFooter />
        </>
    );
};

export default LayoutKeyWordPages;
