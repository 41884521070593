import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SRContactForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mobNumber: '',
    name: 'test',
    email: 'customer@gmail.com', // Default or gathered from the form
    // Add other fields as necessary
  });

  // State for submission message
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);


  const imagePaths = {
    fsRentals1 : require("../../assets/img/fs-1.png"),
    fsRentals2 : require("../../assets/img/fs-2.png"),
    fsRentals3 : require("../../assets/img/fs-3.png"), 
}


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const contactForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://srcartravels.in/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        const data = await response.text(); // Use .text() initially to avoid JSON parsing issues
        setIsSuccess(true);
        setMessage('Form submitted successfully.');
        navigate('/thank-you');

      } else {
        // The server responded with an error status
        const errorText = await response.text(); // Get the response text to display or log
        console.error('Server responded with an error:', errorText);
        setIsSuccess(false);
        setMessage('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setIsSuccess(false);
      setMessage('An error occurred. Please try again.');
    }
  };


  return (
    <>
     
    <div className="container-fluid  align-items-center bg-light p-5">
      <div class="row">
      {/* Content column */}
      <div className="col-md-6 px-md-5 px-0 pb-5 pb-md-0"> 
        <form id="form" onSubmit={contactForm}>
                    <div className="card border-0 bg-rounded-0 ">
                 
                      <div className="card-body px-md-4 px-3 border">
                        {/* Name Field */}
                        <h5 className="py-3">Get In Touch! For Booking or More Details</h5>
                        <div className="mb-3 text-center">
                          <div className="input-group"> 
                            <input type="text" className="form-control" placeholder="Full Name" name="name" onChange={handleChange} />
                          </div>
                        </div>
                        {/* Phone Field */}
                        <div className="mb-3 text-center">
                          <div className="input-group"> 
                            <input type="text" className="form-control" pattern="[6789][0-9]{9}" placeholder="+91 Phone" name="mobNumber" required onChange={handleChange} />
                          </div>
                        </div>
                        {/* Email Field */}
                        <div className="mb-3 text-center ">
                          <div className="input-group"> 
                            <input type="email" className="form-control" placeholder="Email" name="email" onChange={handleChange} />
                          </div>
                        </div>

                        <div className="mb-3 text-center ">
                          <div className="input-group"> 
                          <textarea placeholder="Message" name="message" class="form-control" rows="3" onChange={handleChange}></textarea>
                          </div>
                        </div>

                        {/* Submit Button */}
                        <div className="text-left">
                          <input type="submit" value="Submit" className="btn btn-info btn-block rounded-0 py-2 text-white" />
                        </div>
                      </div>
                    </div>
        </form>
      </div>

      {/* Image column */}
      <div className="col-md-5  ">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d61207.51335938589!2d80.6298875!3d16.5023742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fb8e656cf61d%3A0xe4d4203c2e9acc69!2sSR%20Car%20Travels!5e0!3m2!1sen!2sin!4v1721910449401!5m2!1sen!2sin"
        width="100%"
        height="410"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
        title="SR Car Travels In Vijayawada"
        ></iframe>
      </div>
      </div>
</div>


  
<div className="container py-5">  
    <h2 className="text-center mb-4">
        <b>Why Choose SR Car Travels ?</b>
    </h2>

    <div className="container my-5">
      <div className="row">
        {/* Review 1 */}
        <div className="col-lg-4 col-md-6 mb-1">
          <div className="card border-0">
              <LazyLoadImage
                  src={imagePaths.fsRentals1}
                  alt="Explore Vijayawada with SR Car Travels"
                  effect="blur"
                  className="img-fluid rounded"
              />
            <div className="card-header bg-white border-bottom-0 px-0 mt-3">
              <h5 className="mb-0">Rent a Car in Vijayawada for outstation travel, day-based rentals, and airport transfers</h5> 
            </div>
            <div className="card-body px-0">
              <p className="card-text">
              Rent a car in Vijayawada with SR Car Travels for a variety of needs, including outstation travel, day-based rentals, and convenient airport transfers. Select from our diverse fleet of well-maintained vehicles, guaranteeing a comfortable and stress-free journey. Benefit from competitive pricing, experienced drivers, and customisable booking options to meet your travel needs.
              </p><br />
            </div>
          </div>
        </div>

        {/* Review 2 */}
        <div className="col-lg-4 col-md-6 mb-1">
          <div className="card border-0">
              <LazyLoadImage
                  src={imagePaths.fsRentals2}
                  alt="Explore Vijayawada with SR Car Travels"
                  effect="blur"
                  className="img-fluid rounded"
              />
            <div className="card-header bg-white border-bottom-0 px-0 mt-3">
              <h5 className="mb-0">Best time to rent a car in Vijayawada</h5> 
            </div>
            <div className="card-body px-0">
              <p className="card-text">
              When renting a car in Vijayawada, opt for weekdays to avoid the weekend rush and take advantage of better rates. For a seamless experience, search for <a href="https://srcartravels.in/car-travellers-near-me">"car travellers near me"</a> to discover SR Car Travels. We provide flexible booking options, competitive prices, and various vehicles to meet your requirements.
              </p>
            </div>
          </div>
        </div>

        {/* Review 3 */}
        <div className="col-lg-4 col-md-6 mb-1">
          <div className="card border-0">
            <LazyLoadImage
                  src={imagePaths.fsRentals3}
                  alt="Explore Vijayawada with SR Car Travels"
                  effect="blur"
                  className="img-fluid rounded"
            />
            <div className="card-header bg-white border-bottom-0 px-0 mt-3">
              <h5 className="mb-0">Things to look at while booking an outstation cab to and from Vijayawada</h5> 
            </div>
            <div className="card-body px-0">
              <p className="card-text">
              When booking an outstation cab to and from Vijayawada, various factors must be considered. Prioritize the vehicle's condition, driver's experience, pricing, and customer reviews to make an informed decision. Additionally, ensure that the service includes insurance coverage and 24/7 customer support for your peace of mind. 
              </p><br />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">  
        <a href="tel:8886556632" className="btn btn-info text-white" >Get In Touch With Us</a> 
      </div>
    </div>
    
  </div>





    </>
  );
};

export default SRContactForm;
