import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import SRVijayawadaCityTour from "../components/common/SRVijayawadaCityTour";
import SRPancharamaKshetras from "../components/common/SRPancharamaKshetras";
import SROutstationServices from "../components/common/SROutstationServices";
import SRSpecialOffers from "../components/common/SRSpecialOffers";
import SRLoginComponent from "../components/common/SRLoginComponent";
import SRPrivacyPolicy from '../components/common/SRPrivacyPolicy';
import RidesDuesComponent from '../features/RidesDuesComponent';
import BookingPage from '../features/BookingPage';
import Custom404 from '../error/Custom404';
import DriverDashboardComponent from '../features/DriverDashboardComponent';
import DriverAttendanceForm from '../features/DriverAttendanceForm';
import DriverLeaveForm from '../features/DriverLeaveForm';
import AttendanceComponent from '../features/AttendanceComponent';
import DriverLeaveRecords from '../features/DriverLeaveRecords';
import SRBestCarTravelsInVijayawada from '../KeyWordsCoveredContent/SRBestCarTravelsInVijayawada';
import SRCarRentalsVijayawada from '../KeyWordsCoveredContent/SRCarRentalsVijayawada';
import SRCarTravelsVijayawada from '../KeyWordsCoveredContent/SRCarTravelsVijayawada';
import SRCabsFromVijayawadaToHyderabad from '../KeyWordsCoveredContent/SRCabsFromVijayawadaToHyderabad';
import SRHyderabadToVijayawadaCabBooking from '../KeyWordsCoveredContent/SRHyderabadToVijayawadaCabBooking';
import SRCarTravelsVijayawadaAP from '../KeyWordsCoveredContent/SRCarTravelsVijayawadaAP';
import SRCarRentalsVijayawadaAP from '../KeyWordsCoveredContent/SRCarRentalsVijayawadaAP';
import SRCarTravelersNearMe from '../KeyWordsCoveredContent/SRCarTravelersNearMe';
import SRVijayawadaToHyderabadCabs from '../KeyWordsCoveredContent/SRVijayawadaToHyderabadCabs';
import SRTaxiFromHyderabadToVijayawada from '../KeyWordsCoveredContent/SRTaxiFromHyderabadToVijayawada';
import SRCarsForRentVijayawada from '../KeyWordsCoveredContent/SRCarsForRentVijayawada';
import ThankYouPage from '../components/common/ThankYouPage';
import HomePage from './HomePage';
import Layout from '../layout/Layout';
import LayoutKeyWordPages from '../layout/LayoutKeyWordPages';
import ThankYouLayOut from '../layout/ThankYouLayOut';
import BestCarTravelsInGuntur from '../KeyWordsCoveredContent/BestCarTravelsInGuntur';
import HyderabadToVijayawadaCabs from '../KeyWordsCoveredContent/HyderabadToVijayawadaCabs';
import CabServicesInVijayawada from '../KeyWordsCoveredContent/CabServicesInVijayawada';
import AddDriverForm from '../components/common/AddDriverForm';
import TaxiFromVijayawadaToHyderabad from '../KeyWordsCoveredContent/TaxiFromVijayawadaToHyderabad';
import LongDriveCarsVijayawada from '../KeyWordsCoveredContent/LongDriveCarsVijayawada';
import VijayawadaTaxiServices from '../KeyWordsCoveredContent/VijayawadaTaxiServices';
import TaxiServicesInVijayawada from '../KeyWordsCoveredContent/TaxiServicesInVijayawada';
import CarRentalsInVijayawada from '../KeyWordsCoveredContent/CarRentalsInVijayawada';
import CarTravelsInGuntur from '../KeyWordsCoveredContent/CarTravelsInGuntur';
import CabsInVijayawada from '../KeyWordsCoveredContent/CabsInVijayawada';
import About from '../components/common/About';
import Contactus from '../components/common/SRContactUs';
import InsideGuideInNavigatingVijayawada from '../LandingPages/InsideGuideInNavigatingVijayawada';
import ExperienceComfortWithSRCars from '../LandingPages/ExperienceComfortWithSRCars';
import UnderstandingtheSRCarTravels from '../LandingPages/UnderstandingtheSRCarTravels';
import ExploringtheScenicRoute from '../LandingPages/ExploringtheScenicRoute';
import CustomizeYourOutstationTravel from '../LandingPages/CustomizeYourOutstationTravel';
import HyderabadtoVijayawadaTravelMadeEasy from '../LandingPages/HyderabadtoVijayawadaTravelMadeEasy';
import BusinessFasterwithSRCarTravels from '../LandingPages/BusinessFasterwithSRCarTravels';

function MyRootApp() {

    return (
        <Router>
            <Routes>
                
                <Route path="*" element={<Layout><Custom404 /></Layout>} />
                <Route path="/" element={<Layout><HomePage /></Layout>} />
                <Route path="/about" element={<Layout><About /></Layout>} />
                <Route path="/contact-us" element={<Layout><Contactus /></Layout>} />
                <Route path="/vijayawada-city-tour" element={<Layout><SRVijayawadaCityTour /></Layout>} />
                <Route path="/pancharama-kshetras" element={<Layout><SRPancharamaKshetras /></Layout>} />
                <Route path="/pancharama-kshetras/*" element={<Navigate to="/pancharama-kshetras" replace />} />
                <Route path="/outstation" element={<Layout><SROutstationServices /></Layout>} />
                <Route path="/outstation/*" element={<Navigate to="/outstation" replace />} />
                <Route path="/sp-offers" element={<Layout><SRSpecialOffers /></Layout>} />
                <Route path="/sp-offers/*" element={<Navigate to="/sp-offers" replace />} />
                <Route path="/login" element={<Layout><SRLoginComponent /></Layout>} />
                <Route path="/privacy-policy" element={<Layout><SRPrivacyPolicy /></Layout>} />
                <Route path="/role_owner" element={<Layout><RidesDuesComponent /></Layout>} />
                <Route path="/role_driver" element={<Layout><DriverDashboardComponent /></Layout>} />
                <Route path="/add_driver" element={<Layout><AddDriverForm /></Layout>} />
                <Route path="/booking" element={<Layout><BookingPage /></Layout>} />
                <Route path="/attendance" element={<Layout><DriverAttendanceForm /></Layout>} />
                <Route path="/apply_leave" element={<Layout><DriverLeaveForm /></Layout>} />
                <Route path="/attendances" element={<Layout><AttendanceComponent /></Layout>} />
                <Route path="/driver_leaves" element={<Layout><DriverLeaveRecords /></Layout>} />
                <Route path="/best-car-travels-in-vijayawada" element={<LayoutKeyWordPages><SRBestCarTravelsInVijayawada /></LayoutKeyWordPages>} />
                <Route path="/car-rentals-vijayawada" element={<LayoutKeyWordPages><SRCarRentalsVijayawada /></LayoutKeyWordPages>} />
                <Route path="/car-travels-vijayawada" element={<LayoutKeyWordPages><SRCarTravelsVijayawada /></LayoutKeyWordPages>} />
                <Route path="/cabs-from-vijayawada-to-hyderabad" element={<LayoutKeyWordPages><SRCabsFromVijayawadaToHyderabad /></LayoutKeyWordPages>} />
                <Route path="/hyderabad-to-vijayawada-cab-booking" element={<LayoutKeyWordPages><SRHyderabadToVijayawadaCabBooking /></LayoutKeyWordPages>} />
                <Route path="/car-travels-vijayawada-ap" element={<LayoutKeyWordPages><SRCarTravelsVijayawadaAP /></LayoutKeyWordPages>} />
                <Route path="/car-rentals-vijayawada-ap" element={<LayoutKeyWordPages><SRCarRentalsVijayawadaAP /></LayoutKeyWordPages>} />
                <Route path="/car-travellers-near-me" element={<LayoutKeyWordPages><SRCarTravelersNearMe /></LayoutKeyWordPages>} />
                <Route path="/car-travelers-near-me" element={<Navigate to="/car-travellers-near-me" replace />} />
                <Route path="/vijayawada-to-hyderabad-cabs" element={<LayoutKeyWordPages><SRVijayawadaToHyderabadCabs /></LayoutKeyWordPages>} />
                <Route path="/taxi-from-hyderabad-to-vijayawada" element={<LayoutKeyWordPages><SRTaxiFromHyderabadToVijayawada /></LayoutKeyWordPages>} />
                <Route path="/cars-for-rent-vijayawada" element={<LayoutKeyWordPages><SRCarsForRentVijayawada /></LayoutKeyWordPages>} />
                <Route path="/thank-you" element={<ThankYouLayOut><ThankYouPage /></ThankYouLayOut>} />
                <Route path="/best-car-travels-in-guntur" element={<Layout><BestCarTravelsInGuntur /></Layout>} />
                <Route path="/hyderabad-to-vijayawada-cabs" element={<Layout><HyderabadToVijayawadaCabs /></Layout>} />
                <Route path="/cab-services-in-vijayawada" element={<Layout><CabServicesInVijayawada /></Layout>} />
                <Route path="/taxi-from-vijayawada-to-hyderabad" element={<Layout><TaxiFromVijayawadaToHyderabad /></Layout>} />
                <Route path="/long-drive-cars-vijayawada" element={<Layout><LongDriveCarsVijayawada /></Layout>} />
                <Route path="/vijayawada-taxi-services" element={<Layout><VijayawadaTaxiServices /></Layout>} />
                <Route path="/cabs-in-vijayawada" element={<Layout><CabsInVijayawada /></Layout>} />
                <Route path="/car-travels-in-guntur" element={<Layout><CarTravelsInGuntur /></Layout>} />
                <Route path="/car-rentals-in-vijayawada" element={<Layout><CarRentalsInVijayawada /></Layout>} />
                <Route path="/taxi-services-in-vijayawada" element={<Layout><TaxiServicesInVijayawada /></Layout>} />
                 
                <Route path="/insider-guide-to-navigate-in-vijayawada" element={<Layout><InsideGuideInNavigatingVijayawada /></Layout>} />
                <Route path="/experience-comfort-with-srcars" element={<Layout><ExperienceComfortWithSRCars /></Layout>} />
                <Route path="/understanding-the-SRCarTravels" element={<Layout><UnderstandingtheSRCarTravels /></Layout>} />
                <Route path="/exploring-the=scenic-route" element={<Layout><ExploringtheScenicRoute /></Layout>} />
                <Route path="/customize-your-outstation-travel" element={<Layout><CustomizeYourOutstationTravel /></Layout>} />
                <Route path="/hyderabad-to-vijayawada-trave-made-easy" element={<Layout><HyderabadtoVijayawadaTravelMadeEasy /></Layout>} />
                <Route path="/business-faster-with-srcar-travels" element={<Layout><BusinessFasterwithSRCarTravels /></Layout>} />
            </Routes>
        </Router>

    );
}

export default MyRootApp;
