import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import SRTravelOptions from './SRTravelOptions';
import CabServiceHighlights from './CabServiceHighlights';
import BookNowHydToVij from './BookNowHydToVij';
import RentACarHydToVij from './RentACarHydToVij';
import FAQsComponentHydToVij from './FAQsComponentHydToVij';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/hv_img_1.png"),
    require("../assets/img/g_frame_2.png"),
    require("../assets/img/g_frame_3.png")
];

const HyderabadToVijayawadaCabs = () => {
    return (
        <>
            <Helmet>
                <title>Hyderabad to Vijayawada Cabs | Hyd to Vijayawada Cabs |SR Car Travels</title>
                <meta name="description" content="SR Car Travels' Hyderabad to Vijayawada cabs service extends to both local and outstation travel, allowing you to book cabs from Hyderabad to explore your preferred destinations easily." />
                <link rel="canonical" href="https://srcartravels.in/hyderabad-to-vijayawada-cabs" />
                <meta name="robots" content="index, follow" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Hyderabad to Vijayawada Cabs | Hyd to Vijayawada Cabs |SR Car Travels" />
                <meta property="og:description" content="SR Car Travels' Hyderabad to Vijayawada cabs service extends to both local and outstation travel, allowing you to book cabs from Hyderabad to explore your preferred destinations easily." />

            </Helmet>
            <SRCustomForm /> 
            <div className="container py-5">
                <br/><br/><br/><br/>
                <div className="row align-items-center flex-row-reverse">
                    {/* Image column */}
                    <div className="col-lg-4">
                        <LazyLoadImage
                            src={imagePaths[0]}
                            alt="hyderabad to guntur cabs"
                            effect="blur"
                            width="100%"
                            height="auto"
                            style={{ maxWidth: "350px", borderRadius: "20px" }}
                            className="img-fluid rounded mx-auto d-block"
                        />
                    </div>
                    {/* Content column */}
                    <div className="col-lg-8">
                        <h1 className="fs-4 fw-bold mb-3">Comfortable and Safe Hyderabad to Vijayawada Outstation Journey with SR Travels Cab Services</h1>
                        <p className="fs-6">Your outstation travel, simplified. With SR Car Travels, book your comfortable, reliable ride in just a few clicks. Enjoy on-time pickups, flexible options, and affordable prices. Whether you're traveling alone or with family and friends, SR Car Hyderabad to Vijayawada Cabs Service ensures you reach your destination relaxed and refreshed.</p>
                        <p className="fs-6"><a href='https://srcartravels.in/'>SR Car Travels</a> offers an extensive range of car rental options in Hyderabad to meet diverse needs. Their rental fleet includes compact swift dezires, luxurious sedans, and spacious options like Innova, which are ideal for group travel. Every vehicle is equipped with air conditioning and undergoes regular cleaning and maintenance, ensuring high-quality service standards.
                            Experience the convenience you deserve!
                        </p>
                    </div>
                </div>
            </div>
            <SRTravelOptions />
            <CabServiceHighlights />
            <BookNowHydToVij />
            <RentACarHydToVij />
            <FAQsComponentHydToVij />
        </>
    );
};

export default HyderabadToVijayawadaCabs;
